import axios from "axios";
import moment from "moment";

export default {
  state: {
    projects: [],
  },

  getters: {
    getProjectById: (state) => (projectId) =>
      state.projects.find((project) => {
        return project.id == projectId;
      }),
    currentProjects: (state) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      state.projects.filter(
        // eslint-disable-next-line comma-dangle
        // eslint-disable-next-line max-len, comma-dangle
        (project) => {
          return (
            moment(project.start_date) <= moment() &&
            moment() <= moment(project.end_date) &&
            project.assigned_users.includes(state.me.id)
          );
        }
      ),
  },

  mutations: {
    SET_PROJECTS(state, projects) {
      state.projects = projects;
    },

    UPDATE_PROJECT(state, project) {
      state.projects = [
        ...state.projects.filter((elem) => elem.id !== project.id),
        project,
      ];
    },

    DELETE_PROJECT(state, projectId) {
      state.projects = [
        ...state.projects.filter((elem) => elem.id !== projectId),
      ];
    },
  },

  actions: {
    async getProjects({ commit }) {
      const { data } = await axios.get("projects/");
      commit("SET_PROJECTS", data);
    },

    async createProject({ dispatch }, projectData) {
      await axios.post("projects/", projectData);
      dispatch("getProjects");
    },

    async updateProject({ commit }, projectData) {
      await axios.patch(
        "projects/" + projectData.id.toString() + "/",
        projectData
      );

      commit("UPDATE_PROJECT", projectData);
    },

    async deleteProject({ commit }, id) {
      await axios.delete("projects/" + id.toString() + "/");
      commit("DELETE_PROJECT", id);
    },

    // async createTodo({ dispatch }, todoData) {
    //   dispatch('getProjects');
    //   dispatch('addError', { type: 'success', message: 'Todo gespeichert' });
    // },

    // async updateTodo({ dispatch }, todoData) {
    //   dispatch('getProjects');
    //   dispatch('addError', { type: 'success', message: 'Todo gespeichert' });
    // },

    // async toggleTodo({ dispatch }, todoData) {
    //   dispatch('getProjects');
    //   dispatch('addError', { type: 'success', message: 'Todo gespeichert' });
    // },

    // async deleteTodo({ dispatch }, todoId) {
    //   dispatch('getProjects');
    // }
  },
};
