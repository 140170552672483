<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <form class="space-y-8 divide-y divide-gray-400" @submit.prevent="save()">
        <div class="space-y-8 divide-y divide-gray-400">
          <div>
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Kopfdaten
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                Allgemeine Angaben zu Ihrem Projekt.
              </p>
            </div>

            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-4">
                <label
                  for="title"
                  class="block text-sm font-medium text-gray-700"
                >
                  Titel
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input
                    type="text"
                    name="title"
                    v-model="editedItem.title"
                    id="title"
                    required
                    autocomplete="title"
                    class="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                  />
                </div>
              </div>

              <div class="sm:col-span-6">
                <label
                  for="description"
                  class="block text-sm font-medium text-gray-700"
                >
                  Beschreibung
                </label>
                <div class="mt-1">
                  <textarea
                    id="description"
                    name="description"
                    v-model="editedItem.description"
                    rows="3"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
                <p class="mt-2 text-sm text-gray-500">
                  Eine kurze Projektbeschreibung
                </p>
              </div>

              <div class="sm:col-span-2">
                <label
                  for="start_date"
                  class="block text-sm font-medium text-gray-700"
                >
                  Projektstart
                </label>

                <div class="mt-1">
                  <ejs-datepicker
                    id="start_date"
                    name="start_date"
                    placeholder="Projektstart"
                    v-model:value="editedItem.start_date"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div class="sm:col-span-2">
                <label
                  for="end_date"
                  class="block text-sm font-medium text-gray-700"
                >
                  Projektende
                </label>

                <div class="mt-1">
                  <ejs-datepicker
                    id="end_date"
                    name="end_date"
                    placeholder="Projektende"
                    v-model:value="editedItem.end_date"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="pt-8">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Adressdaten
              </h3>
              <p class="mt-1 text-sm text-gray-500">Adressdaten des Kunden</p>
            </div>
            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <label
                  for="name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Kundenname / Firmenname
                </label>
                <div class="mt-1">
                  <input
                    type="text"
                    name="name"
                    required
                    v-model="editedItem.name"
                    id="name"
                    autocomplete="name"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label
                  for="street"
                  class="block text-sm font-medium text-gray-700"
                >
                  Straße & Hausnummer
                </label>
                <div class="mt-1">
                  <input
                    type="text"
                    required
                    name="street"
                    v-model="editedItem.street"
                    id="street"
                    autocomplete="street"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label
                  for="zip"
                  class="block text-sm font-medium text-gray-700"
                >
                  Postleitzahl
                </label>
                <div class="mt-1">
                  <input
                    type="text"
                    name="zip"
                    required
                    v-model="editedItem.zip"
                    id="zip"
                    autocomplete="zip"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label
                  for="city"
                  class="block text-sm font-medium text-gray-700"
                >
                  Stadt
                </label>
                <div class="mt-1">
                  <input
                    type="text"
                    name="city"
                    id="city"
                    required
                    v-model="editedItem.city"
                    autocomplete="address-level2"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700"
                >
                  Country
                </label>
                <div class="mt-1">
                  <select
                    id="country"
                    name="country"
                    v-model="editedItem.country"
                    autocomplete="country-name"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value="DE">Deutschland</option>
                    <option value="NL">Niederlande</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-8">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Mitglieder
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                Mitarbeiter des Projektes
              </p>
            </div>

            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <ejs-multiselect
                  id="multiselect"
                  :dataSource="users"
                  :fields="multiSelectFields"
                  v-model:value="editedItem.assigned_users"
                  placeholder="Mitglieder auswählen"
                ></ejs-multiselect>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5">
          <div class="flex justify-end">
            <button
              type="button"
              v-if="!isNewProject"
              class="bg-red-600 text-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Löschen
            </button>
            <button
              type="submit"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Speichern
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <SectionWrapper
    v-if="!isNewProject && editedItem.id"
    title="Arbeitszeiten"
    :subtitle="'Die Arbeitszeiten des Projektes \'' + editedItem.title + '\'.'"
  >
    <ClockCalendar calendarType="project" :id="editedItem.id" />
  </SectionWrapper>
</template>

<script>
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";
import { mapActions, mapGetters, mapState } from "vuex";
import SectionWrapper from "../components/SectionWrapper.vue";
import ClockCalendar from "../components/ClockCalendar.vue";

const emptyProject = {
  title: "",
  description: "",
  start_date: new Date(),
  end_date: new Date(),
  name: "",
  street: "",
  zip: "",
  city: "",
  country: "DE",
  assigned_users: [],
};

export default {
  data: function () {
    return {
      editedItem: { ...emptyProject },
      waterMark: "Wählen Sie einen Zeitraum",
      date: new Date("05/11/2017"),
      multiSelectFields: {
        text: "full_name",
        value: "id",
      },
    };
  },

  computed: {
    projectId() {
      return this.$route.params.id;
    },
    isNewProject() {
      return this.projectId === "new";
    },
    currentProject() {
      return this.getProjectById(parseInt(this.projectId));
    },
    ...mapGetters(["getProjectById"]),

    ...mapState(["users"]),
  },

  components: {
    "ejs-datepicker": DatePickerComponent,
    "ejs-multiselect": MultiSelectComponent,
    SectionWrapper,
    ClockCalendar,
  },

  mounted() {
    if (!this.isNewProject) {
      this.editedItem = {
        ...this.currentProject,
        start_date: new Date(this.currentProject.start_date),
        end_date: new Date(this.currentProject.end_date),
      };
    }
  },

  watch: {
    // Keep this in mind
    // projects() {
    //   if (!this.isNewProject) {
    //     this.editedItem = {
    //       ...this.currentProject,
    //       start_date: new Date(this.currentProject.start_date),
    //       end_date: new Date(this.currentProject.end_date),
    //     };
    //   }
    // },
  },

  methods: {
    ...mapActions(["updateProject", "createProject"]),

    async save() {
      if (!this.isNewProject) {
        try {
          await this.updateProject({
            ...this.editedItem,
            start_date: this.editedItem.start_date
              .toISOString()
              .substring(0, 10),
            end_date: this.editedItem.end_date.toISOString().substring(0, 10),
          });
          this.$router.push("/projects");
          alert("Projekt gespeichert!");
        } catch (error) {
          alert(error.response.data.detail);
        }
      } else {
        try {
          await this.createProject({
            ...this.editedItem,
            start_date: this.editedItem.start_date
              .toISOString()
              .substring(0, 10),
            end_date: this.editedItem.end_date.toISOString().substring(0, 10),
          });
          this.$router.push("/projects");
          alert("Projekt gespeichert!");
        } catch (error) {
          alert(error.response.data.detail);
        }
      }
    },
  },
};
</script>

<style>
/* @import "../../node_modules/@syncfusion/ej2/tailwind.css"; */
</style>
