<template>
  <div>
    <div
      class="px-4 py-4 sm:px-6 border-y border-gray-200"
      v-for="i in device.number_of_channels"
      v-bind:key="i"
    >
      <div class="mb-4">
        <h3 class="text-lg leading-4 font-medium text-gray-900">
          {{ device.channel_names[i] }}
        </h3>
        <p class="text-sm font-medium text-gray-500">Kanal {{ i }}</p>
      </div>

      <div class="flex justify-start space-x-4">
        <button
          type="button"
          @click.prevent="sendCommand({ type: 'activate_channel', channel: i })"
          class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <LightningBoltIcon class="-ml-0.5 mr-2 h-6 w-6" aria-hidden="true" />
          Einschalten
        </button>

        <button
          type="button"
          @click.prevent="
            sendCommand({ type: 'deactivate_channel', channel: i })
          "
          class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <XIcon class="-ml-0.5 mr-2 h-6 w-6" aria-hidden="true" />
          Ausschalten
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { LightningBoltIcon, XIcon } from "@heroicons/vue/outline";
import { mapActions } from "vuex";

export default {
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  components: {
    LightningBoltIcon,
    XIcon,
  },
  methods: {
    ...mapActions(["commandDevice"]),

    async sendCommand(command) {
      await this.commandDevice({
        deviceType: "relayDevice",
        deviceId: this.device.id,
        command,
      });
    },
  },
};
</script>

<style></style>
