<template>
  <div class="px-4 sm:px-6">
    <div class="py-5">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{
          isWorking ? "Status: Bei der Arbeit" : "Status: Nicht bei der Arbeit"
        }}
      </h3>
      <p v-if="isWorking" class="mt-1 max-w-2xl text-sm text-gray-500">
        Arbeit gestartet um:
        {{ new Date(workingSince).toLocaleTimeString("de-DE") }}
      </p>
    </div>
  </div>

  <div
    class="px-4 pb-5 sm:px-6 border-t-border-gray-200 flex md:flex-wrap space-x-3"
  >
    <button
      :disabled="isWorking"
      @click="showClockModal = true"
      class="disabled:opacity-50 disabled:bg-gray-500 inline-flex items-center my-2 px-6 py-3 border border-transparent text-lg font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
    >
      Arbeit beginnen
    </button>

    <button
      :disabled="!isWorking"
      @click="showClockModal = true"
      class="disabled:opacity-50 disabled:bg-gray-500 inline-flex items-center my-2 px-6 py-3 border border-transparent text-lg font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
    >
      Arbeit beenden
    </button>
  </div>

  <ClockModal :open="showClockModal" @close-modal="showClockModal = false" />
</template>
<script>
import { mapGetters, mapState } from "vuex";
import ClockModal from "./ClockModal.vue";

export default {
  components: {
    ClockModal,
  },

  data: () => {
    return {
      showClockModal: false,
    };
  },

  computed: {
    ...mapGetters(["isWorking", "workingSince"]),
    ...mapState(["me"]),
  },
};
</script>

<style></style>
