<template>
  <ul role="list" class="divide-y divide-gray-200 border-t">
    <li v-for="project in projects" :key="project.id">
      <router-link
        :to="{ name: 'ProjectDetails', params: { id: project.id } }"
        class="block hover:bg-gray-50"
      >
        <div class="flex items-center px-4 py-4 sm:px-6">
          <div class="min-w-0 flex-1 flex items-center">
            <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p class="text-sm font-medium text-indigo-600 truncate">
                  {{ project.title }}
                </p>
                <p class="mt-2 flex items-center text-sm text-gray-500">
                  <span class="truncate">{{ project.description }}</span>
                </p>
                <p class="text-sm text-gray-500">
                  <time :datetime="new Date(project.start_date)">{{
                    new Date(project.start_date).toLocaleDateString("de-DE")
                  }}</time>
                  -
                  <time :datetime="new Date(project.end_date)">{{
                    new Date(project.end_date).toLocaleDateString("de-DE")
                  }}</time>
                </p>
              </div>

              <div class="hidden md:block">
                <div>
                  <p class="mt-2 flex items-center text-sm text-gray-500">
                    {{ project.name }}
                  </p>
                  <p class="mt-2 flex items-center text-sm text-gray-500">
                    {{
                      project.street + ", " + project.zip + " " + project.city
                    }}
                  </p>
                  <p class="mt-2 flex items-center text-sm text-gray-500">
                    {{ project.country }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon
              class="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </router-link>
    </li>
  </ul>
</template>

<script>
import { ChevronRightIcon } from "@heroicons/vue/solid";
import { mapState } from "vuex";

export default {
  components: {
    ChevronRightIcon,
  },

  computed: {
    ...mapState(["projects"]),
  },
};
</script>

<style></style>
