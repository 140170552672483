import axios from "axios";

export default {
  state: {
    offlineClocks: [],
    myClocks: [],
  },

  getters: {
    todaysClocks: (state) => {
      return state.myClocks
        .concat(state.offlineClocks)
        .filter((clock) => {
          const today = new Date();
          const clockDate = new Date(clock.clock_time);
          return (
            clockDate.getDate() == today.getDate() &&
            clockDate.getMonth() == today.getMonth() &&
            clockDate.getFullYear() == today.getFullYear()
          );
        })
        .sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(a.clock_time) - new Date(b.clock_time);
        });
    },

    isWorking: (_state, getters) => getters.todaysClocks.length % 2 === 1,
    workingSince: (_state, getters) =>
      getters.todaysClocks[getters.todaysClocks.length - 1].clock_time,
  },

  mutations: {
    ADD_OFFLINE_CLOCK(state, clockData) {
      state.offlineClocks = [
        ...state.offlineClocks,
        {
          ...clockData,
          clock_time: new Date().toISOString(),
          user: state.me.id,
        },
      ];
    },

    DELETE_OFFLINE_CLOCKS(state, element) {
      state.myClocks = [
        ...state.myClocks,
        state.offlineClocks.splice(state.offlineClocks.indexOf(element), 1)[0],
      ];
    },
    SET_MY_CLOCKS(state, data) {
      state.myClocks = data;
    },
  },

  actions: {
    async createClock({ commit }, clockData) {
      await axios.post("clocks/", clockData);
      commit("DELETE_OFFLINE_CLOCKS", clockData);
    },

    async createOnlineClock(_, clockData) {
      await axios.post("clocks/", clockData);
    },

    async deleteClock(_, id) {
      await axios.delete("clocks/" + id.toString() + "/");
      // commit("DELETE_CLOCK", id);
    },

    async updateClock(_, clockData) {
      await axios.patch("clocks/" + clockData.id.toString() + "/", clockData);
      // commit("DELETE_CLOCK", id);
    },

    async createOfflineClock({ commit, dispatch }, clockData) {
      commit("ADD_OFFLINE_CLOCK", clockData);
      dispatch("syncClocks");
    },

    async syncClocks({ dispatch, state }) {
      state.offlineClocks.forEach(async (element) => {
        await dispatch("createClock", element);
      });
    },

    async getMyClocks({ commit }) {
      const { data } = await axios.get("clocks/");
      commit("SET_MY_CLOCKS", data);
    },
  },
};
