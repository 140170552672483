export default {
  state: {
    loading: false,
  },

  getters: {},

  mutations: {
    SET_LOADING_STATUS(state, status) {
      state.loading = status;
    },
  },

  actions: {
    async tryRefreshAll({ dispatch }) {
      dispatch("getMe");
      dispatch("getUsers");
      dispatch("getProjects");
      dispatch("getMyClocks");
      dispatch("getDepartments");
      dispatch("getPermissionGroups");
      dispatch("getGateDevices");
      dispatch("getClockDevices");
      dispatch("getRelayDevices");
      // dispatch('syncClocks');
    },

    async setLoading({ commit }, status) {
      commit("SET_LOADING_STATUS", status);
    },
  },
};
