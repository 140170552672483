import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import store from "./store/index";

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
  ? process.env.VUE_APP_API_ENDPOINT
  : "https://api.werkelmax.de/api/";
// "http://localhost:8001/api/";
axios.interceptors.request.use((config) => {
  config.headers.Authorization = "Bearer " + localStorage.getItem("auth-token");
  return config;
});

axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // this can be ignored

    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (
      error.request.responseURL ==
      axios.defaults.baseURL + "token/refresh/"
    ) {
      store.dispatch("logOut");
      console.log("Logging you out since tokens expired");
    }

    return Promise.reject(error);
  }
);

// Instantiate the interceptor (you can chain it as it returns the axios instance)
// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) => {
  if (failedRequest.request.responseURL == axios.defaults.baseURL + "token/") {
    return Promise.reject("Ungültige Kombination aus Benutzername & Passwort");
  } else {
    const prom = axios
      .post("token/refresh/", {
        refresh: localStorage.getItem("refresh-token"),
      })
      .then((tokenRefreshResponse) => {
        localStorage.setItem("auth-token", tokenRefreshResponse.data.access);
        failedRequest.response.config.headers["Authorization"] =
          "Bearer " + tokenRefreshResponse.data.access;
        return Promise.resolve();
      });
    return prom;
  }
};

createAuthRefreshInterceptor(axios, refreshAuthLogic);
