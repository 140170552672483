<template>
  <SectionWrapper
    title="Deine Benutzer"
    subtitle="Diese Mitarbeiter sind zur Nutzung von Werkelmax berechtigt."
  >
    <template v-slot:action>
      <button
        type="button"
        @click.prevent="this.$router.push('/users/new')"
        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <PlusIcon class="-ml-0.5 mr-2 h-6 w-6" aria-hidden="true" />
        Neuer Benutzer
      </button>
    </template>
    <AllUsersList />
  </SectionWrapper>
</template>

<script>
import SectionWrapper from "../components/SectionWrapper.vue";
import AllUsersList from "../components/AllUsersList.vue";
import { PlusIcon } from "@heroicons/vue/solid";

export default {
  components: {
    SectionWrapper,
    AllUsersList,
    PlusIcon,
  },
};
</script>

<style></style>
