import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/LoginView.vue";
import TenantSettingsView from "../views/TenantSettingsView.vue";
import CloudControlView from "../views/CloudControlView.vue";
import ProjectsView from "../views/ProjectsView.vue";
import ProjectDetailsView from "../views/ProjectDetailsView.vue";
import UserDetailsView from "../views/UserDetailsView.vue";
import GroupDetailsView from "../views/GroupDetailsView.vue";
import DeviceDetailsView from "../views/DeviceDetailsView.vue";
import UsersView from "../views/UsersView.vue";
import GroupsView from "../views/GroupsView.vue";
import PlannerView from "../views/PlannerView.vue";
import DashboardView from "../views/DashboardView.vue";
import DefaultLayout from "../components/DefaultLayout.vue";
import Authlayout from "../components/AuthLayout.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: DefaultLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/tenantSettings",
        name: "Settings",
        component: TenantSettingsView,
      },
      {
        path: "/cloudControl",
        name: "CloudControl",
        component: CloudControlView,
      },
      {
        path: "/projects",
        name: "Projects",
        component: ProjectsView,
      },
      {
        path: "/projects/:id",
        name: "ProjectDetails",
        component: ProjectDetailsView,
      },
      {
        path: "/users",
        name: "Users",
        component: UsersView,
      },
      {
        path: "/groups",
        name: "Groups",
        component: GroupsView,
      },
      {
        path: "/groups/:type/:id",
        name: "GroupDetails",
        component: GroupDetailsView,
      },
      {
        path: "/cloudControl/:type/:id",
        name: "DeviceDetails",
        component: DeviceDetailsView,
      },

      {
        path: "/users/:id",
        name: "UserDetails",
        component: UserDetailsView,
      },

      {
        path: "/planner",
        name: "Planner",
        component: PlannerView,
      },

      {
        path: "/dashboard",
        name: "Dashboard",
        component: DashboardView,
      },
    ],
  },
  {
    path: "/auth",
    redirect: "/login",
    name: "Auth",
    component: Authlayout,
    children: [
      {
        path: "/login",
        name: "Login",
        component: LoginView,
      },
    ],
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !localStorage.getItem("auth-token")) {
    next({ name: "Login" });
  } else if (localStorage.getItem("auth-token") && to.name === "Login") {
    next({ name: "Dashboard" });
  } else {
    next();
  }
});

export default router;
