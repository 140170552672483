<template>
  <ErrorBadges />
  <router-view></router-view>
</template>

<script>
import {
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  DragAndDrop,
  Resize,
  TimelineViews,
  TimelineMonth,
} from "@syncfusion/ej2-vue-schedule";
import ErrorBadges from "./components/ErrorBadges.vue";

export default {
  components: {
    ErrorBadges,
  },
  provide: {
    schedule: [
      Day,
      Week,
      WorkWeek,
      Month,
      Agenda,
      DragAndDrop,
      Resize,
      TimelineViews,
      TimelineMonth,
    ],
  },
};
</script>

<style>
@import "../node_modules/@syncfusion/ej2/tailwind.css";
</style>
