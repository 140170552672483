<template>
  <ul role="list" class="divide-y divide-gray-200 border-t">
    <li v-for="device in queryset" :key="device.id">
      <router-link
        :to="{
          name: 'DeviceDetails',
          params: { id: device.id, type: deviceType },
        }"
        class="block hover:bg-gray-50"
      >
        <div class="flex items-center px-4 py-4 sm:px-6">
          <div class="min-w-0 flex-1 flex items-center">
            <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p class="text-sm font-medium text-indigo-600 truncate">
                  {{ device.verbose_device_name }}
                </p>
                <p class="mt-2 flex items-center text-sm text-gray-500">
                  <HashtagIcon class="h-5 w-5" />
                  <span class="ml-1">{{ device.device_name }}</span>
                </p>

                <!-- <p
                  v-if="
                    deviceType === 'relayDevice' || deviceType === 'gateDevice'
                  "
                >
                  Status: <span>{{ device.status }}</span>
                </p> -->
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon
              class="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </router-link>
    </li>
  </ul>
</template>

<script>
import { ChevronRightIcon, HashtagIcon } from "@heroicons/vue/solid";

export default {
  props: {
    queryset: {
      type: Array,
      required: true,
    },
    deviceType: {
      type: String,
      required: true,
    },
  },
  components: {
    ChevronRightIcon,
    HashtagIcon,
  },
};
</script>

<style></style>
