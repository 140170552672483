import axios from "axios";

export default {
  state: {
    gateDevices: [],
    relayDevices: [],
    clockDevices: [],
  },

  getters: {
    getGateDeviceById: (state) => (gateDeviceId) =>
      state.gateDevices.find((gateDevice) => gateDevice.id == gateDeviceId),
    getRelayDeviceById: (state) => (relayDeviceId) =>
      state.relayDevices.find((relayDevice) => relayDevice.id == relayDeviceId),
    getClockDeviceById: (state) => (clockDeviceId) =>
      state.clockDevices.find((clockDevice) => clockDevice.id == clockDeviceId),
  },

  mutations: {
    SET_GATE_DEVICES(state, gateDevices) {
      state.gateDevices = gateDevices;
    },

    SET_RELAY_DEVICES(state, relayDevices) {
      state.relayDevices = relayDevices;
    },
    SET_CLOCK_DEVICES(state, clockDevices) {
      state.clockDevices = clockDevices;
    },

    UPDATE_GATE_DEVICE(state, gateDevice) {
      state.gateDevices = [
        ...state.gateDevices.filter((elem) => elem.id !== gateDevice.id),
        gateDevice,
      ];
    },

    UPDATE_RELAY_DEVICE(state, relayDevice) {
      state.relayDevices = [
        ...state.relayDevices.filter((elem) => elem.id !== relayDevice.id),
        relayDevice,
      ];
    },

    UPDATE_CLOCK_DEVICE(state, clockDevice) {
      state.clockDevices = [
        ...state.clockDevices.filter((elem) => elem.id !== clockDevice.id),
        clockDevice,
      ];
    },
  },

  actions: {
    async getGateDevices({ commit }) {
      const { data } = await axios.get("gate_devices/");
      commit("SET_GATE_DEVICES", data);
    },

    async getRelayDevices({ commit }) {
      const { data } = await axios.get("relay_devices/");
      commit("SET_RELAY_DEVICES", data);
    },

    async getClockDevices({ commit }) {
      const { data } = await axios.get("clock_devices/");
      commit("SET_CLOCK_DEVICES", data);
    },

    async updateGateDevice({ commit }, gateDeviceData) {
      const { data } = await axios.patch(
        "gate_devices/" + gateDeviceData.id.toString() + "/",
        gateDeviceData
      );
      commit("UPDATE_GATE_DEVICE", data);
    },

    async updateRelayDevice({ commit, dispatch }, relayDeviceData) {
      const { data } = await axios.patch(
        "relay_devices/" + relayDeviceData.id.toString() + "/",
        relayDeviceData
      );
      commit("UPDATE_RELAY_DEVICE", data);
      dispatch("getRelayDevices");
    },

    async updateClockDevice({ commit }, clockDeviceData) {
      const { data } = await axios.patch(
        "clock_devices/" + clockDeviceData.id.toString() + "/",
        clockDeviceData
      );
      commit("UPDATE_CLOCK_DEVICE", data);
    },

    async commandDevice(_, { deviceType, deviceId, command }) {
      switch (deviceType) {
        case "gateDevice":
          await axios.post(
            "gate_devices/" + deviceId.toString() + "/command/",
            command
          );
          break;
        case "relayDevice":
          await axios.post(
            "relay_devices/" + deviceId.toString() + "/command/",
            command
          );
          break;
        case "clockDevice":
          await axios.post(
            "clock_devices/" + deviceId.toString() + "/command/",
            command
          );
          break;
      }
    },
  },
};
