<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <form class="space-y-8 divide-y divide-gray-400" @submit.prevent="save()">
        <div class="space-y-8 divide-y divide-gray-400">
          <div>
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                {{ groupType === "department" ? "Abteilung" : "Gruppe" }}
                bearbeiten
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                Hier kannst du die
                {{ groupType === "department" ? "Abteilung" : "Gruppe" }}
                bearbeiten oder löschen.
              </p>
            </div>
            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-2">
                <label
                  for="name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <div class="mt-1">
                  <input
                    type="text"
                    name="name"
                    required
                    v-model="name"
                    id="name"
                    autocomplete="name"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-2">
                <label
                  for="name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Mitglieder
                </label>
                <div class="mt-1">
                  <ejs-multiselect
                    id="members"
                    :dataSource="users"
                    mode="Box"
                    :fields="fields"
                    :placeholder="waterMark"
                    v-model:value="members"
                  ></ejs-multiselect>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-5">
            <div class="flex justify-end">
              <button
                type="button"
                @click="deleteObj()"
                class="bg-red-600 text-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Löschen
              </button>
              <button
                type="submit"
                class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Speichern
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";

export default {
  data: function () {
    return {
      waterMark: "Mitglieder",
      members: [],
      name: "",
      id: 0,
      fields: { text: "full_name", value: "email" },
    };
  },

  components: {
    "ejs-multiselect": MultiSelectComponent,
  },

  computed: {
    groupId() {
      return this.$route.params.id;
    },
    groupType() {
      return this.$route.params.type;
    },
    currentGroup() {
      if (this.groupType === "department") {
        return this.getDepartmentById(parseInt(this.groupId));
      } else {
        return this.getPermissionGroupById(parseInt(this.groupId));
      }
    },
    ...mapGetters(["getPermissionGroupById", "getDepartmentById"]),

    ...mapState(["users"]),
  },

  mounted() {
    this.name = this.currentGroup.name;
    this.members = this.currentGroup.members.map((member) => member.email);
    this.id = this.currentGroup.id;
  },

  watch: {},

  methods: {
    ...mapActions([
      "updatePermissionGroup",
      "deletePermissionGroup",
      "updateDepartment",
      "deleteDepartment",
    ]),

    async deleteObj() {
      if (this.groupType === "department") {
        await this.deleteDepartment(this.id);
      } else {
        await this.deletePermissionGroup(this.id);
      }
      this.$router.push("/groups");
    },

    async save() {
      try {
        if (this.groupType === "department") {
          await this.updateDepartment({
            id: this.id,
            name: this.name,
            members: this.members,
          });
        } else {
          await this.updatePermissionGroup({
            id: this.id,
            name: this.name,
            members: this.members,
          });
        }

        this.$router.push("/groups");
        // alert("Gruppe / Abteilung gespeichert!");
      } catch (error) {
        alert(error);
      }
    },
  },
};
</script>

<style>
/* @import "../../node_modules/@syncfusion/ej2/tailwind.css"; */
</style>
