<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6 flex justify-between">
      <div>
        <h3 class="text-lg leading-6 font-medium text-gray-900">{{ title }}</h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">{{ subtitle }}</p>
      </div>
      <div>
        <slot name="action"> </slot>
      </div>
    </div>

    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: () => "Titel",
    },
    subtitle: {
      type: String,
      required: true,
      default: () => "Subtitel",
    },
  },
};
</script>

<style></style>
