/* eslint-disable no-unused-vars */
import { createStore } from "vuex";

import authStore from "./Auth";
import clockStore from "./Clock";
import projectStore from "./Project";
import userStore from "./User";
import absenceStore from "./Absence";
import miscStore from "./Misc";
import errorStore from "./Error";
import cloudControlStore from "./CloudControl";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export const getDefaultState = () => {
  return {
    ...authStore.state,
    ...clockStore.state,
    ...projectStore.state,
    ...userStore.state,
    ...absenceStore.state,
    ...miscStore.state,
    ...errorStore.state,
    ...cloudControlStore.state,
  };
};

const store = createStore({
  state: getDefaultState(),

  getters: {
    ...authStore.getters,
    ...clockStore.getters,
    ...projectStore.getters,
    ...userStore.getters,
    ...absenceStore.getters,
    ...miscStore.getters,
    ...errorStore.getters,
    ...cloudControlStore.getters,
  },

  mutations: {
    ...authStore.mutations,
    ...clockStore.mutations,
    ...projectStore.mutations,
    ...userStore.mutations,
    ...absenceStore.mutations,
    ...miscStore.mutations,
    ...errorStore.mutations,
    ...cloudControlStore.mutations,

    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },

  actions: {
    ...authStore.actions,
    ...clockStore.actions,
    ...projectStore.actions,
    ...userStore.actions,
    ...absenceStore.actions,
    ...miscStore.actions,
    ...errorStore.actions,
    ...cloudControlStore.actions,
  },
  plugins: [vuexLocal.plugin],
});

export default store;
