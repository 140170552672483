<template>
  <div class="px-4 pb-5 sm:px-6">
    <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
      <div class="sm:col-span-2">
        <label
          for="selectedIndex"
          class="block text-sm font-medium text-gray-700"
        >
          Speicherplatz
        </label>
        <div class="mt-1">
          <input
            type="number"
            name="selectedIndex"
            v-model="selectedIndex"
            id="selectedIndex"
            autocomplete="selectedIndex"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>
    </div>
    <div class="my-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
      <div class="sm:col-span-2">
        <label
          for="selectedUser"
          class="block text-sm font-medium text-gray-700"
        >
          Benutzer
        </label>
        <div class="mt-1">
          <ejs-combobox
            id="selectedUser"
            :dataSource="users"
            mode="Box"
            :fields="fieldsUsers"
            placeholder="Benutzer wählen"
            v-model:value="selectedUser"
          ></ejs-combobox>
        </div>
      </div>
    </div>
    <button
      type="button"
      @click.prevent="
        sendCommand({
          type: 'initialize_fingerprint',
          fingerprint: selectedIndex,
          user: selectedUser,
        })
      "
      class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      <UserAddIcon class="-ml-0.5 mr-2 h-6 w-6" aria-hidden="true" />
      Benutzer anlernen
    </button>
    <div v-if="device.fingerprint_mappings">
      <hr class="my-5" />

      <div>
        <div class="mt-8 flow-root">
          <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div
              class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
            >
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Speicherplatz
                    </th>
                    <th
                      scope="col"
                      class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Benutzer
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                  <tr
                    v-for="(value, propertyName) in device.fingerprint_mappings"
                    :key="propertyName"
                  >
                    <td
                      class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                    >
                      {{ propertyName }}
                    </td>
                    <td
                      class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                    >
                      {{ getUserById(value).full_name }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { UserAddIcon } from "@heroicons/vue/outline";
import { ComboBoxComponent } from "@syncfusion/ej2-vue-dropdowns";

export default {
  data: function () {
    return {
      selectedUser: null,
      selectedIndex: 0,
      fingerprint_mappings: this.device.fingerprint_mappings,
      waterMarkUsers: "Benutzer auswählen",
      fieldsUsers: { text: "full_name", value: "id" },
    };
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  components: {
    UserAddIcon,
    "ejs-combobox": ComboBoxComponent,
  },
  computed: {
    ...mapState(["users"]),
    ...mapGetters(["getUserById"]),
  },

  methods: {
    ...mapActions(["commandDevice"]),

    async sendCommand(command) {
      await this.commandDevice({
        deviceType: "clockDevice",
        deviceId: this.device.id,
        command,
      });
    },
  },
};
</script>

<style></style>
