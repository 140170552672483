<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg" v-if="editedItem">
    <div class="px-4 py-5 sm:px-6">
      <form class="space-y-8 divide-y divide-gray-400" @submit.prevent="save()">
        <div class="space-y-8 divide-y divide-gray-400">
          <div>
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Nutzerdaten
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                Allgemeine Angaben zu diesem Benuzter.
              </p>
            </div>
            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-2">
                <label
                  for="first_name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Vorname
                </label>
                <div class="mt-1">
                  <input
                    type="text"
                    name="first_name"
                    required
                    v-model="editedItem.first_name"
                    id="first_name"
                    autocomplete="first_name"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label
                  for="last_name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Nachname
                </label>
                <div class="mt-1">
                  <input
                    type="text"
                    name="last_name"
                    required
                    v-model="editedItem.last_name"
                    id="last_name"
                    autocomplete="last_name"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div class="mt-1">
                  <input
                    type="email"
                    name="email"
                    required
                    v-model="editedItem.email"
                    id="email"
                    autocomplete="email"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div class="sm:col-span-2" v-if="isNewUser">
                <label
                  for="password"
                  class="block text-sm font-medium text-gray-700"
                >
                  Passwort
                </label>
                <div class="mt-1">
                  <input
                    type="password"
                    name="password"
                    :required="isNewUser"
                    v-model="editedItem.password"
                    id="password"
                    autocomplete="password"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div class="sm:col-span-2" v-if="isNewUser">
                <label
                  for="password_confirm"
                  class="block text-sm font-medium text-gray-700"
                >
                  Passwort wiederholen
                </label>
                <div class="mt-1">
                  <input
                    type="password"
                    name="password_confirm"
                    :required="isNewUser"
                    v-model="editedItem.password_confirm"
                    id="password_confirm"
                    autocomplete="password_confirm"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label
                  for="rfid_tag"
                  class="block text-sm font-medium text-gray-700"
                >
                  RFID-Chip Nummer
                </label>
                <div class="mt-1">
                  <input
                    type="text"
                    name="rfid_tag"
                    v-model="editedItem.rfid_tag"
                    id="rfid_tag"
                    autocomplete="rfid_tag"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label
                  for="name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Abteilungen
                </label>
                <div class="mt-1">
                  <ejs-combobox
                    id="department"
                    :dataSource="departments"
                    mode="Box"
                    :fields="fieldsGroups"
                    placeholder="Abteilung wählen"
                    v-model:value="editedItem.department"
                  ></ejs-combobox>
                </div>
              </div>

              <div class="sm:col-span-6">
                <fieldset class="space-y-5">
                  <legend class="sr-only">Notifications</legend>
                  <div class="relative flex items-start">
                    <div class="flex items-center h-5">
                      <input
                        id="is_tenant_admin"
                        aria-describedby="is_tenant_admin-description"
                        name="is_tenant_admin"
                        v-model="editedItem.is_tenant_admin"
                        type="checkbox"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label
                        for="is_tenant_admin"
                        class="font-medium text-gray-700"
                        >Ist Admin</label
                      >
                      <p id="is_tenant_admin-description" class="text-gray-500">
                        Dieser Benuzter kann Projekte und Mitarbeiter anlegen
                        und verwalten.
                      </p>
                    </div>
                  </div>

                  <div class="relative flex items-start">
                    <div class="flex items-center h-5">
                      <input
                        id="track_time"
                        aria-describedby="track_time-description"
                        name="track_time"
                        v-model="editedItem.track_time"
                        type="checkbox"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="track_time" class="font-medium text-gray-700"
                        >Zeiterfassung</label
                      >
                      <p id="track_time-description" class="text-gray-500">
                        Dieser Benutzer ist zur Zeiterfassung freigeschaltet.
                      </p>
                    </div>
                  </div>
                  <div class="relative flex items-start">
                    <div class="flex items-center h-5">
                      <input
                        id="is_active"
                        aria-describedby="is_active-description"
                        name="is_active"
                        v-model="editedItem.is_active"
                        type="checkbox"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="is_active" class="font-medium text-gray-700"
                        >Aktiv</label
                      >
                      <p id="is_active-description" class="text-gray-500">
                        Aktive Benutzer können sich anmelden und Werkelmax
                        benutzen, deaktivierte Benutzer verlieren alle
                        Berechtigungen.
                      </p>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>

          <div v-for="(value, key) in permissions" v-bind:key="key">
            <div class="mt-5">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                {{ key }}
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                Rechte für diese Aktion bearbeiten
              </p>
            </div>

            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <input
                  class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  v-model="value.include_all"
                  id="flexCheckDefault"
                />
                <label
                  class="form-check-label inline-block text-gray-800"
                  for="flexCheckDefault"
                >
                  Alle Nutzer
                </label>
              </div>
              <div class="sm:col-span-2">
                <label
                  for="name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Abteilungen
                </label>
                <div class="mt-1">
                  <ejs-multiselect
                    :id="key + '_departments'"
                    :dataSource="departments"
                    mode="Box"
                    :fields="fieldsGroups"
                    :placeholder="waterMarkDepartments"
                    v-model:value="value.departments"
                  ></ejs-multiselect>
                </div>
              </div>

              <div class="sm:col-span-2">
                <label
                  for="name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Rechtegruppen
                </label>
                <div class="mt-1">
                  <ejs-multiselect
                    :id="key + '_permission_groups'"
                    :dataSource="permissionGroups"
                    mode="Box"
                    :fields="fieldsGroups"
                    :placeholder="waterMarkPermissionGroups"
                    v-model:value="value.permission_groups"
                  ></ejs-multiselect>
                </div>
              </div>

              <div class="sm:col-span-2">
                <label
                  for="name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Benutzer
                </label>
                <div class="mt-1">
                  <ejs-multiselect
                    :id="key + '_users'"
                    :dataSource="users"
                    mode="Box"
                    :fields="fieldsUsers"
                    :placeholder="waterMarkUsers"
                    v-model:value="value.users"
                  ></ejs-multiselect>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-5">
            <div class="flex justify-end">
              <button
                type="button"
                v-if="!isNewUser"
                class="bg-red-600 text-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Löschen
              </button>
              <button
                type="submit"
                class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Speichern
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <SectionWrapper
    v-if="!isNewUser && editedItem.id"
    title="Arbeitszeiten"
    :subtitle="'Die Arbeitszeiten des Benutzers ' + editedItem.full_name + '.'"
  >
    <template v-slot:action>
      <button
        type="button"
        @click.prevent="showAbsenceModal = true"
        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <PlusIcon class="-ml-0.5 mr-2 h-6 w-6" aria-hidden="true" />
        Urlaub / Krankheit
      </button>
    </template>

    <ClockCalendarEditable calendarType="user" :id="editedItem.id" />
  </SectionWrapper>

  <AbsenceModal
    :open="showAbsenceModal"
    :user-id="parseInt(userId)"
    @close-modal="showAbsenceModal = false"
  />
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  MultiSelectComponent,
  ComboBoxComponent,
} from "@syncfusion/ej2-vue-dropdowns";
import SectionWrapper from "../components/SectionWrapper.vue";
import { PlusIcon } from "@heroicons/vue/solid";
import ClockCalendarEditable from "../components/ClockCalendarEditable.vue";
import AbsenceModal from "../components/AbsenceModal.vue";

const emtpyUser = {
  first_name: "",
  last_name: "",
  email: "",
  is_tenant_admin: false,
  track_time: true,
  rfid_tag: "",
  permissions: [],
};

export default {
  data: function () {
    return {
      editedItem: { ...emtpyUser },
      waterMarkDepartments: "Abteilungen auswählen",
      waterMarkPermissionGroups: "Rechtegruppen auswählen",
      waterMarkUsers: "Benutzer auswählen",
      permissions: {},
      fieldsGroups: { text: "name", value: "id" },
      fieldsUsers: { text: "full_name", value: "id" },
      showAbsenceModal: false,
    };
  },

  computed: {
    userId() {
      return this.$route.params.id;
    },
    isNewUser() {
      return this.userId === "new";
    },
    currentUser() {
      return this.getUserById(parseInt(this.userId));
    },
    ...mapGetters(["getUserById"]),

    ...mapState(["users", "departments", "permissionGroups"]),
  },

  components: {
    SectionWrapper,
    PlusIcon,
    AbsenceModal,
    ClockCalendarEditable,
    "ejs-multiselect": MultiSelectComponent,
    "ejs-combobox": ComboBoxComponent,
  },

  async mounted() {
    if (!this.isNewUser) {
      if (this.currentUser.first_name) {
        this.editedItem = { ...this.currentUser };
      }
      await this.getUser(this.userId);
      this.editedItem = { ...this.currentUser };
    }

    Object.entries(this.editedItem.permissions).forEach((entry) => {
      const [key, value] = entry;
      if (key.startsWith("perm_")) {
        if (value) {
          this.permissions[key] = value;
        } else {
          this.permissions[key] = {
            departments: [],
            permission_groups: [],
            users: [],
            include_all: false,
          };
        }
      }
    });
  },

  watch: {},

  methods: {
    ...mapActions(["updateUser", "createUser", "getUser"]),

    async save() {
      if (!this.isNewUser) {
        try {
          await this.updateUser({
            ...this.editedItem,
            permissions: this.permissions,
          });
          this.$router.push("/users");
          alert("Benutzer gespeichert!");
        } catch (error) {
          alert(error);
        }
      } else {
        try {
          await this.createUser({
            ...this.editedItem,
            permissions: this.permissions,
          });
          this.$router.push("/users");
          alert("Benutzer gespeichert!");
        } catch (error) {
          alert(error.response.data.detail);
        }
      }
    },
  },
};
</script>

<style>
/* @import "../../node_modules/@syncfusion/ej2/tailwind.css"; */
</style>
