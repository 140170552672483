<template>
  <ejs-schedule
    height="700px"
    width="100%"
    locale="de"
    :readonly="true"
    :eventSettings="eventSettings"
    :selectedDate="selectedDate"
    :currentView="currentView"
    :eventRendered="oneventRendered"
    :navigating="onNavigation"
    :popupOpen="onPopupOpen"
    :firstDayOfWeek="1"
  >
    <e-views>
      <e-view option="Month" showWeekend="true"></e-view>
    </e-views>
  </ejs-schedule>
</template>

<script>
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-vue-schedule";
import axios from "axios";
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    project_start_date: {
      type: String,
      required: false,
    },
    project_end_date: {
      type: String,
      required: false,
    },
    id: {
      type: Number,
      required: false,
    },
    calendarType: {
      type: String,
      required: true,
      default: () => "me",
    },
  },

  data() {
    return {
      currentView: "Month",
      selectedDate: new Date(),
      allowMultiple: true,
      clocks: [],

      eventSettings: {
        dataSource: [],
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false,
      },
    };
  },
  components: {
    "ejs-schedule": ScheduleComponent,
    "e-views": ViewsDirective,
    "e-view": ViewDirective,
  },
  computed: {
    ...mapGetters(["getUserById"]),
    ...mapState(["myClocks"]),
  },

  watch: {
    myClocks() {
      this.refreshData();
    },
  },

  mounted() {
    this.refreshData();
  },
  methods: {
    oneventRendered: function (args) {
      let eventType = args.data.EventType;
      let color = "#000";
      switch (eventType) {
        case "Working":
          color = "#228B22";
          break;
        case "Error":
          color = "#D2042D";
          break;
        default:
          break;
      }
      args.element.style.backgroundColor = color;
    },
    onNavigation(args) {
      if (args.action === "date") {
        if (
          args.previousDate.getMonth() !== args.currentDate.getMonth() ||
          args.previousDate.getYear() !== args.currentDate.getYear()
        ) {
          this.refreshData(args.currentDate);
        }
      }
    },

    refreshData(date = this.selectedDate) {
      if (
        this.calendarType === "me" &&
        date.getMonth() === new Date().getMonth() &&
        date.getYear() === new Date().getYear()
      ) {
        this.createEventsFromClocks();
      } else {
        const params = {
          date: date.toISOString().substring(0, 10),
        };
        if (this.calendarType === "project") {
          params.project_id = this.id;
        } else if (this.calendarType === "user") {
          params.user_id = this.id;
        }

        axios
          .get("clocks/", {
            params,
          })
          .then((response) => {
            this.clocks = response.data;
            this.createEventsFromClocks(date);
          });
      }
    },

    onPopupOpen: function (args) {
      if (
        // args.type === "QuickInfo" ||
        args.type === "EditEventInfo"
        // args.type === "ViewEventInfo"
      ) {
        args.cancel = true;
      }

      if (args.type === "QuickInfo") {
        args.element.querySelector(".e-event-edit").style.display = "none";
        args.element.querySelector(".e-event-delete").style.display = "none";
      }

      if (args.type === "ViewEventInfo") {
        args.element.querySelector(".e-edit").style.display = "none";
        args.element.querySelector(".e-delete").style.display = "none";
      }
    },

    createEventsFromClocks(date = this.selectedDate) {
      let events = [];
      let clockSource = [];

      if (
        this.calendarType === "me" &&
        date.getMonth() === new Date().getMonth() &&
        date.getYear() === new Date().getYear()
      ) {
        clockSource = this.myClocks;
      } else {
        clockSource = this.clocks;
      }

      const groups = clockSource.reduce((groups, clock) => {
        const user = clock.user;
        if (!groups[user]) {
          groups[user] = [];
        }
        groups[user].push(clock);
        return groups;
      }, {});

      // Edit: to add it in the array format instead
      const clocksPerUser = Object.keys(groups).map((user) => {
        return {
          user,
          clocks: groups[user],
        };
      });

      for (let user of clocksPerUser) {
        const userObj = this.getUserById(user.user);
        const groups = user.clocks.reduce((groups, clock) => {
          const date = clock.clock_time.split("T")[0];
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(clock);
          return groups;
        }, {});

        // Edit: to add it in the array format instead
        const clocksPerDay = Object.keys(groups).map((date) => {
          return {
            date,
            clocks: groups[date],
          };
        });

        for (let day of clocksPerDay) {
          for (let j = 0; j < day.clocks.length; j += 2) {
            // RENDER THE EVENTS
            if (day.clocks[j + 1]) {
              let workTime =
                (
                  (new Date(day.clocks[j + 1].clock_time) -
                    new Date(day.clocks[j].clock_time)) /
                  1000 /
                  60 /
                  60
                )
                  .toFixed(2)
                  .toString() + " h";

              let workTimeMinutes = (
                ((new Date(day.clocks[j + 1].clock_time) -
                  new Date(day.clocks[j].clock_time)) /
                  1000 /
                  60) %
                60
              ).toFixed(0);

              let workTimeHours = Math.floor(
                (new Date(day.clocks[j + 1].clock_time) -
                  new Date(day.clocks[j].clock_time)) /
                  1000 /
                  60 /
                  60
              ).toFixed(0);

              // VALID WORK TIME
              if (this.calendarType === "project") {
                events.push({
                  Id: day.clocks[j].id,
                  Subject:
                    userObj.last_name +
                    ", " +
                    userObj.first_name +
                    ": " +
                    workTime,
                  StartTime: new Date(day.clocks[j].clock_time),
                  EndTime: new Date(day.clocks[j + 1].clock_time),
                  EventType: "Working",
                });
              } else if (
                this.calendarType === "user" ||
                this.calendarType === "me"
              ) {
                events.push({
                  Id: day.clocks[j].id,
                  Subject:
                    "Arbeitszeit: " +
                    workTimeHours +
                    "h  " +
                    workTimeMinutes +
                    "m",
                  StartTime: new Date(day.clocks[j].clock_time),
                  EndTime: new Date(day.clocks[j + 1].clock_time),
                  EventType: "Working",
                });
              }
            } else {
              // ERROR
              events.push({
                Id: day.clocks[j].id,
                Subject: "FEHLER",
                StartTime: new Date(day.clocks[j].clock_time),
                EndTime: new Date(day.clocks[j].clock_time),
                EventType: "Error",
              });
            }
          }
        }
      }

      this.eventSettings = { dataSource: events };
    },
  },
};
</script>

<style></style>
