<template>
  <ul role="list" class="divide-y divide-gray-200 border-t">
    <li v-for="user in managedUsers" :key="user.id">
      <router-link
        :to="{ name: 'UserDetails', params: { id: user.id } }"
        class="block hover:bg-gray-50"
      >
        <div class="flex items-center px-4 py-4 sm:px-6">
          <div class="min-w-0 flex-1 flex items-center">
            <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p class="text-sm font-medium text-indigo-600 truncate">
                  {{ user.full_name_rev }}
                </p>
                <p class="mt-2 flex items-center text-sm text-gray-500">
                  <MailIcon class="h-5 w-5" />
                  <span class="ml-1">{{ user.email }}</span>
                </p>
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon
              class="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </router-link>
    </li>
  </ul>
</template>

<script>
import { ChevronRightIcon, MailIcon } from "@heroicons/vue/solid";
import { mapState } from "vuex";

export default {
  components: {
    ChevronRightIcon,
    MailIcon,
  },

  computed: {
    ...mapState(["users", "me"]),
    managedUsers() {
      if (this.me.is_tenant_admin) {
        return this.users;
      }

      const perm_users =
        this.me.permissions.perm_view_clocks_of.user_list_emails;
      return this.users.filter((user) => perm_users.includes(user.email));
    },
  },

  setup() {
    console.log();
    return {};
  },
};
</script>

<style></style>
