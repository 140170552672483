export default {
  de: {
    calendar: { today: "Heute" },
    schedule: {
      edit: "Bearbeiten",
      delete: "Löschen",
      saveButton: "Speichern",
      cancelButton: "Abbrechen",
      deleteButton: "Löschen",
      newEvent: "Neue Stempelzeit",
      editEvent: "Stempelzeit bearbeiten",
      day: "Tag",
      week: "Woche",
      month: "Monat",
      today: "Heute",
    },
  },
};
