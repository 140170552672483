<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-gray-100">
    <body class="h-full">
    ```
  -->
  <div class="min-h-full">
    <Disclosure as="nav" class="bg-gray-600" v-slot="{ open }">
      <div class="mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex-shrink-0">
            <img class="h-10 w-10" src="../assets/logo.png" alt="Workflow" />
          </div>
          <div class="hidden md:block">
            <div class="ml-10 flex items-baseline">
              <div v-for="item in navigation" :key="item.name">
                <router-link
                  v-if="!item.adminRequired || me.is_tenant_admin"
                  :to="item.to"
                  :class="[
                    this.$route.name === item.to.name
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'px-3 py-2 rounded-md text-sm font-medium mx-4',
                  ]"
                  :aria-current="item.current ? 'page' : undefined"
                  >{{ item.name }}</router-link
                >
              </div>
            </div>
          </div>
          <div class="hidden md:block">
            <div class="ml-4 flex items-center md:ml-6">
              <!-- Profile dropdown -->
              <span class="text-white hidden xl:block">{{
                me.full_name_rev
              }}</span>
              <Menu as="div" class="ml-3 relative">
                <div>
                  <MenuButton
                    class="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  >
                    <UserIcon class="h-8 w-8 text-white rounded-full" />
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <MenuItem class="hover:bg-gray-200 hover:text-white]">
                      <a
                        @click="logout"
                        :class="['block px-4 py-2 text-sm text-gray-700']"
                        >Logout</a
                      >
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            >
              <span class="sr-only">Menü</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel class="md:hidden" v-slot="{ close }">
        <div class="px-2 pt-2 pb-3 sm:px-3">
          <div v-for="item in navigation" :key="item.name">
            <router-link
              v-if="!item.adminRequired || me.is_tenant_admin"
              as="a"
              @click="close()"
              :to="item.to"
              :class="[
                this.$route.name === item.to.name
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'block px-3 py-2 rounded-md text-base font-medium my-1',
              ]"
              >{{ item.name }}</router-link
            >
          </div>
        </div>
        <div class="pt-4 pb-3 border-t border-gray-700">
          <div class="flex items-center px-5">
            <div class="flex-shrink-0">
              <MenuIcon class="block h-6 w-6" />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium leading-none text-white">
                {{ me.last_name }}, {{ me.first_name }}
              </div>
              <div class="text-sm font-medium leading-none text-gray-400">
                {{ me.email }}
              </div>
            </div>
          </div>
          <div class="mt-3 px-2 space-y-1">
            <DisclosureButton
              as="a"
              @click="logout"
              class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
              >Logout</DisclosureButton
            >
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
    <main class="flex-1">
      <div class="py-6">
        <div class="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 space-y-5">
          <!-- Replace with your content -->

          <router-view></router-view>
          <!-- /End replace -->
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { MenuIcon, XIcon, UserIcon } from "@heroicons/vue/outline";

import { mapActions } from "vuex";

const navigation = [
  {
    name: "Dashboard",
    adminRequired: false,
    to: { name: "Dashboard" },
  },
  {
    name: "Projekte",
    adminRequired: true,
    to: { name: "Projects" },
  },
  {
    name: "Benutzer",
    adminRequired: false,
    to: { name: "Users" },
  },
  {
    name: "Gruppen",
    adminRequired: true,
    to: { name: "Groups" },
  },

  // {
  //   name: "Kalender",
  //   adminRequired: true,
  //   to: { name: "Planner" },
  // },
  {
    name: "Cloud-Control",
    adminRequired: false,
    to: { name: "CloudControl" },
  },
];

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    // eslint-disable-next-line vue/no-reserved-component-names
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuIcon,
    UserIcon,
    XIcon,
  },
  methods: {
    ...mapActions(["tryRefreshAll"]),
  },
  mounted() {
    this.tryRefreshAll();
  },
  setup() {
    const store = useStore();
    const sidebarOpen = ref(false);

    return {
      me: computed(() => store.state.me),
      navigation,
      sidebarOpen,
      logout: () => {
        store.dispatch("logOut");
      },
    };
  },
};
</script>
