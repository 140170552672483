<template>
  <SectionWrapper
    :title="'Hallo, ' + me.first_name + '!'"
    subtitle="Willkommen bei Werkelmax! Hier kannst du dich von der Arbeit an- und abmelden."
  >
    <ClockContainer />
  </SectionWrapper>
  <SectionWrapper
    v-if="currentProjects.length"
    title="Aktuelle Projekte"
    subtitle="Einer Übersicht deiner Projekte."
  >
    <CurrentProjects />
  </SectionWrapper>

  <SectionWrapper
    title="Dein Monat"
    subtitle="Eine Monatsansicht deiner Arbeitszeiten."
  >
    <ClockCalendar v-if="users.length" calendarType="me" />
  </SectionWrapper>
</template>

<script>
import CurrentProjects from "../components/CurrentProjects.vue";
import ClockContainer from "../components/ClockContainer.vue";
import ClockCalendar from "../components/ClockCalendar.vue";
import SectionWrapper from "../components/SectionWrapper.vue";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    CurrentProjects,
    ClockContainer,
    ClockCalendar,
    SectionWrapper,
  },
  computed: {
    ...mapState(["users", "me"]),
    ...mapGetters(["currentProjects"]),
  },
};
</script>

<style></style>
