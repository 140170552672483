<template>
  <div class="px-4 py-5 sm:px-6">
    <div class="flex justify-start space-x-4">
      <button
        type="button"
        @click.prevent="sendCommand({ type: 'open' })"
        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <LockOpenIcon class="-ml-0.5 mr-2 h-6 w-6" aria-hidden="true" />
        Öffnen
      </button>

      <button
        type="button"
        @click.prevent="sendCommand({ type: 'close' })"
        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <LockClosedIcon class="-ml-0.5 mr-2 h-6 w-6" aria-hidden="true" />
        Schließen
      </button>
    </div>
  </div>
</template>

<script>
import { LockOpenIcon, LockClosedIcon } from "@heroicons/vue/outline";
import { mapActions } from "vuex";
export default {
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  components: {
    LockOpenIcon,
    LockClosedIcon,
  },

  methods: {
    ...mapActions(["commandDevice"]),

    async sendCommand(command) {
      await this.commandDevice({
        deviceType: "gateDevice",
        deviceId: this.device.id,
        command,
      });
    },
  },
};
</script>

<style></style>
