<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div>
              <div class="mt-3 text-center sm:mt-5 space-y-10">
                <DialogTitle
                  as="h3"
                  class="text-xl leading-6 font-medium text-gray-900"
                >
                  {{ isWorking ? "Arbeit beenden" : "Arbeit beginnen" }}
                </DialogTitle>
                <form class="space-y-8">
                  <div class="sm:col-span-3">
                    <label
                      for="project"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Projekt
                    </label>
                    <div class="mt-1">
                      <select
                        id="project"
                        name="project"
                        v-model="clockObject.project"
                        class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      >
                        <option :value="0">---</option>
                        <option
                          v-for="project in currentProjects"
                          :key="project.id"
                          :value="project.id"
                        >
                          {{ project.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="sm:col-span-4">
                    <label
                      for="description"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Beschreibung
                    </label>
                    <div class="mt-1 flex rounded-md shadow-sm">
                      <input
                        v-model="clockObject.description"
                        type="text"
                        name="description"
                        id="description"
                        autocomplete="description"
                        class="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                      />
                    </div>
                  </div>
                </form>
                <div
                  class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
                >
                  <button
                    type="button"
                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    @click="doClock()"
                  >
                    Stempeln
                  </button>
                  <button
                    type="button"
                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    @click="$emit('close-modal')"
                    ref="cancelButtonRef"
                  >
                    Abbrechen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

const defaultClockObject = {
  description: "",
  project: 0,
};

export default {
  props: {
    open: Boolean,
  },

  data() {
    return {
      clockObject: {
        ...defaultClockObject,
      },
    };
  },
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },

  computed: {
    ...mapGetters(["currentProjects", "isWorking"]),
    ...mapState(["me"]),
  },
  methods: {
    doClock() {
      this.$emit("close-modal");
      this.$store.dispatch("createOfflineClock", this.clockObject);
      this.clockObject = { ...defaultClockObject };
    },
  },
};
</script>

<style></style>
