<template>
  <SectionWrapper
    title="Tore / Türen"
    subtitle="Alle Ihrer an CloudControl angebundenen Tore und Türen. Klicken Sie auf einen Eintrag, um Details anzuzeigen und die Geräte zu steuern."
  >
    <DeviceList deviceType="gateDevice" :queryset="gateDevices" />
  </SectionWrapper>

  <SectionWrapper
    title="Relais"
    subtitle="Alle Ihrer an CloudControl angebundenen Relais, wie beispielsweise Lichter oder Maschinen. Klicken Sie auf einen Eintrag, um Details anzuzeigen und die Geräte zu steuern."
  >
    <DeviceList deviceType="relayDevice" :queryset="relayDevices" />
  </SectionWrapper>

  <SectionWrapper
    title="Stempeluhren"
    subtitle="Alle Ihrer an CloudControl angebundenen Stempeluhren. Klicken Sie auf einen Eintrag, um Details anzuzeigen und die Geräte zu steuern."
  >
    <DeviceList deviceType="clockDevice" :queryset="clockDevices" />
  </SectionWrapper>
</template>

<script>
import SectionWrapper from "../components/SectionWrapper.vue";
import DeviceList from "../components/DeviceList.vue";
import { mapState } from "vuex";

export default {
  data: () => {
    return {};
  },
  components: {
    SectionWrapper,
    DeviceList,
  },
  computed: {
    ...mapState(["gateDevices", "relayDevices", "clockDevices"]),
  },
};
</script>

<style></style>
