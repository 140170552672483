<template>
  <ejs-schedule
    height="90vh"
    width="100%"
    :readonly="false"
    :event-settings="eventSettings"
    :selected-date="selectedDate"
    :current-view="currentView"
    locale="de"
    :event-rendered="oneventRendered"
    :navigating="onNavigation"
    :first-day-of-week="1"
    :popup-open="onPopupOpen"
    :action-begin="onActionBegin"
  >
    <e-views>
      <e-view option="Month" show-weekend="true"></e-view>
    </e-views>
  </ejs-schedule>
</template>

<script>
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-vue-schedule";
import { createElement } from "@syncfusion/ej2-base";
import axios from "axios";
// import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { mapGetters, mapState, mapActions } from "vuex";
import { TimePicker } from "@syncfusion/ej2-calendars";

export default {
  props: {
    project_start_date: {
      type: String,
      required: false,
    },
    project_end_date: {
      type: String,
      required: false,
    },
    id: {
      type: Number,
      required: false,
    },
    calendarType: {
      type: String,
      required: true,
      default: () => "me",
    },
  },

  data() {
    return {
      currentView: "Month",
      selectedDate: new Date(),
      allowMultiple: true,
      clocks: [],
      absences: [],

      eventSettings: {
        dataSource: [],
        minimumEventDuration: 0,
        fields: {
          id: "Id",
          // subject: { name: "Subject", title: "Event Name" },

          description: { name: "Description", title: "Beschreibung" },
          startTime: { name: "StartTime", title: "Stempelzeit" },
          // endTime: {
          //   name: "EndTime",
          //   title: "End Time",
          // },
        },
      },
    };
  },
  components: {
    "ejs-schedule": ScheduleComponent,
    "e-views": ViewsDirective,
    "e-view": ViewDirective,
  },
  computed: {
    ...mapGetters(["getUserById"]),
    ...mapState(["myClocks"]),
  },

  // watch: {
  //   myClocks() {
  //     console.log("myClocks changed");
  //     this.refreshData();
  //   },
  // },

  mounted() {
    console.log("Mounted");
    this.refreshData();
  },
  methods: {
    oneventRendered: function (args) {
      let eventType = args.data.EventType;
      let color = "#000";
      switch (eventType) {
        case "In":
          color = "#228B22";
          break;
        case "Out":
          color = "#0047AB";
          break;
        case "Error":
          color = "#D2042D";
          break;
        default:
          color = "#5046e5";
          break;
      }

      args.element.style.backgroundColor = color;

      args.element.querySelectorAll(".e-time").forEach((e) => e.remove());
    },
    onNavigation(args) {
      console.log("On Navigation");
      if (args.action === "date") {
        if (
          args.previousDate.getMonth() !== args.currentDate.getMonth() ||
          args.previousDate.getYear() !== args.currentDate.getYear()
        ) {
          this.refreshData(args.currentDate);
        }
      }
    },

    async refreshData(date = this.selectedDate) {
      console.log("Refresh");
      if (
        this.calendarType === "me" &&
        date.getMonth() === new Date().getMonth() &&
        date.getYear() === new Date().getYear()
      ) {
        this.createEventsFromClocks();
      } else {
        const params = {
          date: date.toISOString().substring(0, 10),
        };
        if (this.calendarType === "project") {
          params.project_id = this.id;
        } else if (this.calendarType === "user") {
          params.user_id = this.id;
        }

        const clockResponse = await axios.get("clocks/", {
          params,
        });

        const absenceResponse = await axios.get("absences/", {
          params,
        });

        this.clocks = clockResponse.data;
        this.absences = absenceResponse.data;

        const clockEvents = this.createEventsFromClocks(date);
        const absenceEvents = this.createEventsFromAbsences();

        this.eventSettings = {
          dataSource: clockEvents.concat(absenceEvents),
        };
      }
    },

    createEventsFromClocks(date = this.selectedDate) {
      let events = [];
      let clockSource = [];

      if (
        this.calendarType === "me" &&
        date.getMonth() === new Date().getMonth() &&
        date.getYear() === new Date().getYear()
      ) {
        clockSource = this.myClocks;
      } else {
        clockSource = this.clocks;
      }

      const groups = clockSource.reduce((groups, clock) => {
        const user = clock.user;
        if (!groups[user]) {
          groups[user] = [];
        }
        groups[user].push(clock);
        return groups;
      }, {});

      // Edit: to add it in the array format instead
      const clocksPerUser = Object.keys(groups).map((user) => {
        return {
          user,
          clocks: groups[user],
        };
      });

      for (let user of clocksPerUser) {
        const userObj = this.getUserById(user.user);
        const groups = user.clocks.reduce((groups, clock) => {
          const date = clock.clock_time.split("T")[0];
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(clock);
          return groups;
        }, {});

        // Edit: to add it in the array format instead
        const clocksPerDay = Object.keys(groups).map((date) => {
          return {
            date,
            clocks: groups[date],
          };
        });

        for (let day of clocksPerDay) {
          for (let j = 0; j < day.clocks.length; j += 1) {
            // RENDER THE EVENTS
            let clockTime = new Date(day.clocks[j].clock_time);

            // VALID WORK TIME
            if (this.calendarType === "project") {
              events.push({
                Id: day.clocks[j].id,
                Subject:
                  userObj.last_name +
                  ", " +
                  userObj.first_name +
                  ": " +
                  clockTime,
                StartTime: new Date(day.clocks[j].clock_time),
                EndTime: new Date(day.clocks[j + 1].clock_time),
                EventType: "Working",
              });
            } else if (
              this.calendarType === "user" ||
              this.calendarType === "me"
            ) {
              let subject = "";
              let eventType = "";

              if (j % 2 === 0) {
                if (day.clocks[j + 1]) {
                  subject =
                    clockTime.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    }) + " Eingestempelt";
                  eventType = "In";
                } else {
                  subject =
                    clockTime.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    }) + " Fehler";
                  eventType = "Error";
                }
              } else {
                subject =
                  clockTime.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  }) + " Ausgestempelt";
                eventType = "Out";
              }
              events.push({
                Id: day.clocks[j].id,
                Subject: subject,
                Description: day.clocks[j].description,
                StartTime: clockTime,
                EndTime: clockTime,
                EventType: eventType,
              });
            }
          }
        }
      }
      return events;
      // this.eventSettings = { dataSource: events };
    },

    createEventsFromAbsences() {
      const events = [];

      for (let absence of this.absences) {
        let subject = "Urlaub";
        switch (absence.category) {
          case "KH":
            subject = "Krankheit";
            break;
          case "UU":
            subject = "Unbezahlt";
            break;
          case "BU":
            subject = "Urlaub";
            break;
          default:
            break;
        }
        events.push({
          Id: "ABS-" + absence.id,
          Subject: subject,
          StartTime: new Date(absence.start_date),
          EndTime: new Date(absence.end_date),
          EventType: absence.category,
        });
      }

      return events;
    },

    onPopupOpen: function (args) {
      if (
        args.type === "QuickInfo" ||
        args.type === "EditEventInfo" ||
        args.type === "ViewEventInfo"
      ) {
        args.cancel = true;
      }
      if (args.type === "Editor") {
        // dont display unneeded fields
        args.element.querySelector(".e-start-end-row").style.display = "none";
        args.element.querySelector(".e-title-location-row").style.display =
          "none";
        args.element.querySelector(".e-editor").style.display = "none";
        args.element.querySelector(".e-all-day-time-zone-row").style.display =
          "none";

        if (args.data.Id?.toString().startsWith("ABS-")) {
          args.element.querySelector(".e-description-container").style.display =
            "none";

          args.element.querySelector(".e-event-save").style.display = "none";

          if (args.element.querySelector(".custom-field-row")) {
            args.element.querySelector(".custom-field-row").style.display =
              "none";
          }

          return;
        } else {
          args.element.querySelector(".e-description-container").style.display =
            "block";
          args.element.querySelector(".e-event-save").style.display =
            "inline-block";

          if (args.element.querySelector(".custom-field-row")) {
            args.element.querySelector(".custom-field-row").style.display =
              "block";
          }
        }

        if (!args.element.querySelector(".custom-field-row")) {
          // Create custom form element for time picker
          let formElement = args.element.querySelector(".e-schedule-form");

          let rowClockTime = createElement("div", {
            className: "custom-field-row",
          });

          formElement.firstChild.insertBefore(
            rowClockTime,
            args.element.querySelector(".e-title-location-row")
          );

          let containerClockTime = createElement("div", {
            className: "custom-field-container",
          });
          let inputEleClockTime = createElement("input", {
            className: "e-field",
            attrs: { name: "ClockTime" },
          });
          containerClockTime.appendChild(inputEleClockTime);
          rowClockTime.appendChild(containerClockTime);

          let timePickerClockTime = new TimePicker({
            fields: { text: "text", value: "value" },
            value: args.data.StartTime.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            floatLabelType: "Always",
            placeholder: "Stempelzeit",
            locale: "de",
          });
          timePickerClockTime.appendTo(inputEleClockTime);
          inputEleClockTime.setAttribute("name", "ClockTime");
        }
      }
    },
    onActionBegin: function (args) {
      switch (args.requestType) {
        case "eventCreate": {
          let createdTime = new Date(args.data[0].StartTime);
          createdTime.setHours(args.data[0].ClockTime.split(":")[0]);
          createdTime.setMinutes(args.data[0].ClockTime.split(":")[1]);
          createdTime.setSeconds(0);

          let createdPayload = {
            clock_time: createdTime,
            description: args.data[0].Description,
            user: this.id,
          };
          this.createOnlineClock(createdPayload).then(this.refreshData());
          break;
        }

        case "eventChange": {
          let changedTime = new Date(args.data.StartTime);
          changedTime.setHours(args.data.ClockTime.split(":")[0]);
          changedTime.setMinutes(args.data.ClockTime.split(":")[1]);
          changedTime.setSeconds(0);

          let changedPayload = {
            id: args.data.Id,
            clock_time: changedTime.toISOString(),
            description: args.data.Description,
          };
          this.updateClock(changedPayload).then(() => {
            this.refreshData();
          });
          break;
        }

        case "eventRemove": {
          if (!args.data[0].Id.toString().startsWith("ABS-")) {
            this.deleteClock(args.data[0].Id).then(() => {
              this.refreshData();
            });
          } else {
            this.deleteAbsence(args.data[0].Id.substring(4)).then(() => {
              this.refreshData();
            });
          }

          break;
        }
      }
    },

    ...mapActions([
      "createOnlineClock",
      "updateClock",
      "deleteClock",
      "createAbsence",
      "deleteAbsence",
    ]),
  },
};
</script>

<style></style>
