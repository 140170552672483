import axios from "axios";

export default {
  state: {
    absences: [],
  },

  getters: {},

  mutations: {
    SET_ABSENCES(state, absences) {
      state.absences = absences;
    },
  },

  actions: {
    async createAbsence(_, absenceData) {
      await axios.post("absences/", absenceData);
    },

    async deleteAbsence(_, id) {
      await axios.delete("absences/" + id.toString() + "/");
    },
  },
};
