export default {
  state: {
    errors: [],
  },

  getters: {
    getErrors: (state) => state.errors,
  },

  // {
  //   message: "This is an error",
  //   type: "error" / "warning" / "success"
  // }

  mutations: {
    SET_ERRORS(state, errors) {
      state.errors = errors;
    },
    ADD_ERRORS(state, errors) {
      state.errors = state.errors.concat(errors);
    },
    ADD_ERROR(state, error) {
      if (!state.errors.some((e) => e.message === error.message)) {
        state.errors.push(error);
      }
      // state.errors.push(error);
    },

    DELETE_ERRORS(state) {
      state.errors = [];
    },
    DELETE_ERROR(state, index) {
      state.errors.splice(index, 1);
    },
  },

  actions: {
    async setErrors({ commit }, errors) {
      commit("SET_ERRORS", errors);
    },
    async addErrors({ commit }, errors) {
      commit("ADD_ERRORS", errors);
    },
    async addError({ commit }, error) {
      commit("ADD_ERROR", error);
    },
    async deleteErrors({ commit }) {
      commit("DELETE_ERRORS");
    },
    async deleteError({ commit }, index) {
      commit("DELETE_ERROR", index);
    },
  },
};
