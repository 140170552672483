import * as numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import * as gregorian from "cldr-data/main/de/ca-gregorian.json";
import * as numbers from "cldr-data/main/de/numbers.json";
import * as timeZoneNames from "cldr-data/main/de/timeZoneNames.json";
import * as weekData from "cldr-data/supplemental/weekData.json"; // To load the culture based first day of week
import { loadCldr, setCurrencyCode, L10n } from "@syncfusion/ej2-base";
import { registerLicense } from "@syncfusion/ej2-base";
import syncfusionLocaleConfig from "./syncfusionLocaleConfig.js";

registerLicense(
  "Mgo+DSMBaFt/QHRqVVhkVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSn9Rd01iWXpedHdURw==;Mgo+DSMBPh8sVXJ0S0J+XE9AflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdURhWHdfcHFVQmRaUA==;ORg4AjUWIQA/Gnt2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZiW35WcXRQRmRaUEU=;MTMwNzU1OUAzMjMwMmUzNDJlMzBKc29QMk5pOU1aVTBndnlrd01YdkN5dEYyUmRDWUF5bzNQYTU2NXhGLzNzPQ==;MTMwNzU2MEAzMjMwMmUzNDJlMzBBSkR3T0FHZThZdW44NnVUeFpMWFloK3B2czcvMmFTcjZ1ZDdhMTUyb2I0PQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVgWH1feHVVQ2BZUk13;MTMwNzU2MkAzMjMwMmUzNDJlMzBXYlo0N0VtWEtXdXpVbGZZOTRYeEtzS1ZIV0YrM1NqaDBpeHNFREh6cjV3PQ==;MTMwNzU2M0AzMjMwMmUzNDJlMzBSd2dzWmQxNkR6VStaeTJnU0gyM2Q0WjhETUlJMXhhT1NLTEhjdSszTzdzPQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZiW35WcXRQRmZdVUU=;MTMwNzU2NUAzMjMwMmUzNDJlMzBUYkdWRE5vOVRUWHJ3R0haa3BpcXYvU2laY1d0N21JeU1aTUowSTdMeU1VPQ==;MTMwNzU2NkAzMjMwMmUzNDJlMzBmWUlpZWlsVnZ3cldKcGcyZ0tHV2NCUjhkeUhlb1IxWm1COHRXbnpkOVpzPQ==;MTMwNzU2N0AzMjMwMmUzNDJlMzBXYlo0N0VtWEtXdXpVbGZZOTRYeEtzS1ZIV0YrM1NqaDBpeHNFREh6cjV3PQ=="
);

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCurrencyCode("EUR");

//load the locale object to set the localized today value
L10n.load(syncfusionLocaleConfig);
