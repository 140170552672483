<template>
  <SectionWrapper
    title="Abteilungen"
    subtitle="Jeder Benutzer kann exakt einer Abteilung zugeordnet sein. Hier kannst du Abteilungen anlegen und Mitarbeiter zuordnen."
  >
    <template v-slot:action>
      <button
        type="button"
        @click.prevent="
          title = 'Neue Abteilung anlegen';
          type = 'createDepartment';
          showNewGroupModal = true;
        "
        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <PlusIcon class="-ml-0.5 mr-2 h-6 w-6" aria-hidden="true" />
        Neue Abteilung
      </button>
    </template>
    <GroupList groupType="department" :queryset="departments" />
  </SectionWrapper>

  <SectionWrapper
    title="Gruppen"
    subtitle="Benutzer können mehreren Gruppen zugeordnet sein. Diese dienen hauptsächlich zur Verwaltung von Rechten. Hier kannst du Gruppen anlegen und Mitarbeiter zuordnen."
  >
    <template v-slot:action>
      <button
        type="button"
        @click.prevent="
          type = 'createPermissionGroup';
          title = 'Neue Gruppe anlegen';
          showNewGroupModal = true;
        "
        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <PlusIcon class="-ml-0.5 mr-2 h-6 w-6" aria-hidden="true" />
        Neue Gruppe
      </button>
    </template>
    <GroupList groupType="permissionGroup" :queryset="permissionGroups" />
  </SectionWrapper>

  <NewGroupModal
    :open="showNewGroupModal"
    :type="type"
    :title="title"
    @close-modal="showNewGroupModal = false"
  />
</template>

<script>
import SectionWrapper from "../components/SectionWrapper.vue";
import GroupList from "../components/GroupList.vue";
import NewGroupModal from "../components/NewGroupModal.vue";
import { PlusIcon } from "@heroicons/vue/solid";
import { mapState } from "vuex";

export default {
  data: () => {
    return {
      showNewGroupModal: false,
      type: "",
      title: "",
    };
  },
  components: {
    SectionWrapper,
    NewGroupModal,
    GroupList,
    PlusIcon,
  },
  computed: {
    ...mapState(["departments", "permissionGroups"]),
  },
};
</script>

<style></style>
