<template>
  <ejs-schedule
    width="100%"
    height="70pc"
    :readonly="true"
    :eventSettings="eventSettings"
    :selectedDate="selectedDate"
    :currentView="currentView"
    :eventRendered="onEventRendered"
    :navigating="onNavigation"
    locale="de"
    :group="group"
  >
    <e-views>
      <e-view option="TimelineMonth"></e-view>
    </e-views>
    <e-resources>
      <e-resource
        field="UserId"
        title="User"
        name="Users"
        :dataSource="userDataSource"
        textField="userName"
        idField="id"
      >
      </e-resource>
    </e-resources>
  </ejs-schedule>
</template>

<script>
import {
  ScheduleComponent,
  ResourcesDirective,
  ResourceDirective,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-vue-schedule";

// import moment from 'moment';
import axios from "axios";
import { mapState } from "vuex";
export default {
  data() {
    return {
      width: "100%",
      height: "550px",
      currentView: "TimelineMonth",
      selectedDate: new Date(),
      selectedDateString: new Date().toISOString().substring(0, 10),
      group: {
        resources: ["Users"],
      },
      events: [],
      allowMultiple: true,

      // userDataSource: [],
    };
  },

  components: {
    "ejs-schedule": ScheduleComponent,
    "e-view": ViewDirective,
    "e-views": ViewsDirective,
    "e-resource": ResourceDirective,
    "e-resources": ResourcesDirective,
  },

  computed: {
    ...mapState(["users"]),
    userDataSource() {
      return this.users
        .map((elem) => {
          return {
            userName: elem.full_name_rev,
            id: elem.id,
            isActive: elem.is_active,
            trackTime: elem.track_time,
          };
        })
        .filter((elem) => {
          return elem.isActive && elem.trackTime;
        });
    },
    eventSettings() {
      return { dataSource: [...this.events] };
    },
  },

  mounted() {
    console.log("mounted");
    this.refreshData();
  },

  methods: {
    onNavigation(args) {
      if (args.action === "date") {
        if (
          args.previousDate.getMonth() !== args.currentDate.getMonth() ||
          args.previousDate.getYear() !== args.currentDate.getYear()
        ) {
          this.refreshData(args.currentDate);
        }
      }
    },

    onEventRendered: function (args) {
      let eventType = args.data.EventType;
      let color = "#000";
      switch (eventType) {
        case "Working":
          color = "#228B22";
          break;
        case "Error":
          color = "#D2042D";
          break;
        default:
          break;
      }
      args.element.style.backgroundColor = color;
    },

    refreshData(date = this.selectedDate) {
      const params = {
        date: date.toISOString().substring(0, 10),
        planner: true,
      };
      axios
        .get("clocks/", {
          params,
        })
        .then((response) => {
          this.clocks = response.data;
          this.createEventsFromClocks();
        });
    },
    createEventsFromClocks() {
      let events = [];
      // this gives an object with dates as keys
      const groups = this.clocks.reduce((groups, clock) => {
        const user = clock.user;
        if (!groups[user]) {
          groups[user] = [];
        }
        groups[user].push(clock);
        return groups;
      }, {});
      // Edit: to add it in the array format instead
      const clocksPerUser = Object.keys(groups).map((user) => {
        return {
          user,
          clocks: groups[user],
        };
      });
      for (let user of clocksPerUser) {
        // const userObj = this.getUserById(user.user);
        // userData.push({
        //   userName: userObj.full_name_rev,
        //   id: userObj.id,
        // });
        const groups = user.clocks.reduce((groups, clock) => {
          const date = clock.clock_time.split("T")[0];
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(clock);
          return groups;
        }, {});
        // Edit: to add it in the array format instead
        const clocksPerDay = Object.keys(groups).map((date) => {
          return {
            date,
            clocks: groups[date],
          };
        });

        for (let day of clocksPerDay) {
          for (let j = 0; j < day.clocks.length; j += 2) {
            if (day.clocks[j + 1]) {
              let workTime =
                (
                  (new Date(day.clocks[j + 1].clock_time) -
                    new Date(day.clocks[j].clock_time)) /
                  1000 /
                  60 /
                  60
                )
                  .toFixed(2)
                  .toString() + " h";
              events.push({
                Subject: workTime,
                StartTime: new Date(day.clocks[j].clock_time),
                EndTime: new Date(day.clocks[j + 1].clock_time),
                StartId: day.clocks[j].id,
                EndId: day.clocks[j + 1].id,
                UserId: parseInt(user.user),
                EventType: "Working",
                // Id: 5
              });
              j;
            } else {
              events.push({
                Subject: "FEHLER",
                StartTime: new Date(day.clocks[j].clock_time),
                EndTime: new Date(day.clocks[j].clock_time),
                UserId: parseInt(user.user),
                EventType: "Error",
                // Id: 5
              });
            }
          }
        }
      }

      this.events = [...events];
      console.log(this.events);
    },
  },
};
</script>
<style></style>
