<template>
  <div>
    <img
      class="mx-auto h-40 w-auto"
      src="../assets/logo.png"
      alt="Werkelmax-Logo"
    />
    <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
      Bitte loggen Sie sich ein
    </h2>
  </div>
  <form class="mt-8 space-y-6" @submit.stop="login">
    <div v-if="errorMsg" class="py-3 px-5 bg-red-500 text-white rounded">
      {{ errorMsg }}
    </div>

    <input type="hidden" name="remember" value="true" />
    <div class="rounded-md shadow-sm -space-y-px">
      <div>
        <label for="email-address" class="sr-only">Email Adresse</label>
        <input
          id="email-address"
          v-model="authDetails.email"
          name="email"
          type="email"
          autocomplete="email"
          required=""
          class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
          placeholder="Email Adresse"
        />
      </div>
      <div>
        <label for="password" class="sr-only">Passwort</label>
        <input
          id="password"
          v-model="authDetails.password"
          name="password"
          type="password"
          autocomplete="current-password"
          required=""
          class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
          placeholder="Passwort"
        />
      </div>
    </div>

    <div>
      <button
        type="submit"
        class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <span class="absolute left-0 inset-y-0 flex items-center pl-3">
          <LockClosedIcon
            class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
            aria-hidden="true"
          />
        </span>
        Login
      </button>
    </div>
  </form>
</template>

<script setup>
import { LockClosedIcon } from "@heroicons/vue/solid";
import store from "../store";
import { ref } from "vue";

let errorMsg = ref("");

const authDetails = {
  email: "",
  password: "",
};

function login(ev) {
  ev.preventDefault();

  store.dispatch("login", authDetails).catch((err) => {
    errorMsg.value = err;
  });
}
</script>
