import { createApp } from "vue";

import App from "./App.vue";
import "./index.css";
import router from "./router/index";
import store from "./store/index";

import "moment/locale/de";

import "./registerServiceWorker";
import "./syncfusionConfig.js";
import "./axiosConfig.js";

createApp(App).use(router).use(store).mount("#app");
