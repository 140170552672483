<template>
  <SectionWrapper
    title="Steuerung"
    subtitle="Hier kannst du dein Gerät steuern."
  >
    <GateDeviceCommands
      :device="currentDevice"
      v-if="deviceType === 'gateDevice'"
    />
    <RelayDeviceCommands
      :device="currentDevice"
      v-if="deviceType === 'relayDevice'"
    />

    <ClockDeviceCommands
      :device="currentDevice"
      v-if="deviceType === 'clockDevice'"
    />
  </SectionWrapper>
  <div
    class="bg-white shadow overflow-hidden sm:rounded-lg"
    v-if="me.is_tenant_admin"
  >
    <div class="px-4 py-5 sm:px-6">
      <form class="space-y-8 divide-y divide-gray-400" @submit.prevent="save()">
        <div class="space-y-8 divide-y divide-gray-400">
          <div>
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Cloud-Control Gerät bearbeiten
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                Hier kannst du das Cloud-Control Gerät bearbeiten.
              </p>
            </div>
            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-2">
                <label
                  for="name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Alphanumerische ID
                </label>

                <div class="mt-1">
                  <input
                    type="text"
                    name="name"
                    disabled
                    v-model="device.device_name"
                    id="name"
                    class="shadow-sm disabled block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>

            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-2">
                <label
                  for="name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Lesbarer Name
                </label>
                <div class="mt-1">
                  <input
                    type="text"
                    name="name"
                    v-model="device.verbose_device_name"
                    id="name"
                    class="shadow-sm readonly focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-for="(value, key) in permissions" v-bind:key="key">
            <div class="mt-5 mb-3">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                {{ key }}
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                Rechte für diese Aktion bearbeiten
              </p>
            </div>

            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <input
                  class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  v-model="value.include_all"
                  :id="key + '-include_all'"
                />
                <label
                  class="form-check-label inline-block text-gray-800"
                  :for="key + '-include_all'"
                >
                  Alle Nutzer haben diese Berechtigung
                </label>
              </div>

              <div class="sm:col-span-2">
                <label
                  :for="key + '_departments'"
                  class="block text-sm font-medium text-gray-700"
                >
                  Abteilungen
                </label>
                <div class="mt-1">
                  <ejs-multiselect
                    :id="key + '_departments'"
                    :dataSource="departments"
                    mode="Box"
                    :fields="fieldsGroups"
                    :placeholder="waterMarkDepartments"
                    v-model:value="value.departments"
                  ></ejs-multiselect>
                </div>
              </div>

              <div class="sm:col-span-2">
                <label
                  :for="key + '_permission_groups'"
                  class="block text-sm font-medium text-gray-700"
                >
                  Rechtegruppen
                </label>
                <div class="mt-1">
                  <ejs-multiselect
                    :id="key + '_permission_groups'"
                    :dataSource="permissionGroups"
                    mode="Box"
                    :fields="fieldsGroups"
                    :placeholder="waterMarkPermissionGroups"
                    v-model:value="value.permission_groups"
                  ></ejs-multiselect>
                </div>
              </div>

              <div class="sm:col-span-2">
                <label
                  :for="key + '_users'"
                  class="block text-sm font-medium text-gray-700"
                >
                  Benutzer
                </label>
                <div class="mt-1">
                  <ejs-multiselect
                    :id="key + '_users'"
                    :dataSource="users"
                    mode="Box"
                    :fields="fieldsUsers"
                    :placeholder="waterMarkUsers"
                    v-model:value="value.users"
                  ></ejs-multiselect>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-5">
            <div class="flex justify-end">
              <button
                type="submit"
                class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Speichern
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";
import SectionWrapper from "../components/SectionWrapper.vue";
import GateDeviceCommands from "../components/GateDeviceCommands.vue";
import RelayDeviceCommands from "../components/RelayDeviceCommands.vue";
import ClockDeviceCommands from "../components/ClockDeviceCommands.vue";

export default {
  data: function () {
    return {
      permissions: {},
      waterMarkDepartments: "Abteilungen auswählen",
      waterMarkPermissionGroups: "Rechtegruppen auswählen",
      waterMarkUsers: "Benutzer auswählen",
      device: {},
      fieldsGroups: { text: "name", value: "id" },
      fieldsUsers: { text: "full_name", value: "id" },
    };
  },

  components: {
    "ejs-multiselect": MultiSelectComponent,
    SectionWrapper,
    GateDeviceCommands,
    RelayDeviceCommands,
    ClockDeviceCommands,
  },

  computed: {
    deviceId() {
      return this.$route.params.id;
    },
    deviceType() {
      return this.$route.params.type;
    },
    currentDevice() {
      switch (this.deviceType) {
        case "gateDevice":
          return this.getGateDeviceById(parseInt(this.deviceId));
        case "relayDevice":
          return this.getRelayDeviceById(parseInt(this.deviceId));
        case "clockDevice":
          return this.getClockDeviceById(parseInt(this.deviceId));
        default:
          return null;
      }
    },
    ...mapGetters([
      "getGateDeviceById",
      "getRelayDeviceById",
      "getClockDeviceById",
    ]),

    ...mapState(["departments", "permissionGroups", "users", "me"]),
  },
  mounted() {
    Object.entries(this.currentDevice.permissions).forEach((entry) => {
      const [key, value] = entry;
      if (key.startsWith("perm_")) {
        if (value) {
          this.permissions[key] = value;
        } else {
          this.permissions[key] = {
            departments: [],
            permission_groups: [],
            users: [],
            include_all: false,
          };
        }
      }
    });
    this.device = { ...this.currentDevice };
  },
  methods: {
    ...mapActions([
      "updateGateDevice",
      "updateRelayDevice",
      "updateClockDevice",
      "commandDevice",
    ]),

    async save() {
      try {
        switch (this.deviceType) {
          case "gateDevice":
            await this.updateGateDevice({
              ...this.device,
              permissions: this.permissions,
            });

            break;
          case "relayDevice":
            await this.updateRelayDevice({
              ...this.device,
              permissions: this.permissions,
            });
            break;
          case "clockDevice":
            await this.updateClockDevice({
              ...this.device,
              permissions: this.permissions,
            });
            break;
        }

        alert("Gerät gespeichert!");
        this.$router.push("/cloudControl");
      } catch (error) {
        alert(error);
      }
    },
  },
};
</script>

<style></style>
