<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div>
              <div class="mt-3 text-center sm:mt-5 space-y-10">
                <DialogTitle
                  as="h3"
                  class="text-xl leading-6 font-medium text-gray-900"
                >
                  Urlaub / Krankheit hinzufügen
                </DialogTitle>
                <form class="space-y-8">
                  <div class="">
                    <label
                      for="project"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Typ
                    </label>
                    <div class="mt-1">
                      <select
                        id="project"
                        name="project"
                        v-model="absenceObject.category"
                        class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      >
                        <option
                          v-for="absenceCategory in absenceCategories"
                          :key="absenceCategory.id"
                          :value="absenceCategory.id"
                        >
                          {{ absenceCategory.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between">
                    <div>
                      <label
                        for="description"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Startdatum
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <input
                          v-model="absenceObject.start"
                          type="text"
                          name="start"
                          id="start"
                          autocomplete="start"
                          class="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        for="description"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Enddatum
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <input
                          v-model="absenceObject.end"
                          type="text"
                          name="end"
                          id="end"
                          autocomplete="end"
                          class="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <div
                  class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
                >
                  <button
                    type="button"
                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    @click="saveAbsence()"
                  >
                    Speichern
                  </button>
                  <button
                    type="button"
                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    @click="$emit('close-modal')"
                    ref="cancelButtonRef"
                  >
                    Abbrechen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
// import { mapGetters, mapState } from "vuex";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

const defaultAbsenceObject = {
  start: new Date().toLocaleDateString("de-DE"),
  end: new Date().toLocaleDateString("de-DE"),
  category: "KH",
};

export default {
  props: {
    open: Boolean,
    userId: Number,
  },

  data() {
    return {
      absenceObject: {
        ...defaultAbsenceObject,
      },
      absenceCategories: [
        { id: "KH", title: "Krankheit" },
        { id: "BU", title: "Urlaub" },
        { id: "UU", title: "Unbezahlt" },
      ],
    };
  },
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },

  computed: {},
  methods: {
    async saveAbsence() {
      await this.$store.dispatch("createAbsence", {
        ...this.absenceObject,
        user_id: this.$props.userId,
      });
      this.$emit("close-modal");
      this.$emit("saved-absence");
      this.absenceObject = { ...defaultAbsenceObject };
    },
  },
};
</script>

<style></style>
