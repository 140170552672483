<template>
  <SectionWrapper
    title="Planungsansicht"
    subtitle="Plane hier deine Benutzer effizient ein."
  >
    <PlannerComponent />
  </SectionWrapper>
</template>

<script>
import SectionWrapper from "../components/SectionWrapper.vue";
import PlannerComponent from "../components/PlannerComponent.vue";

export default {
  components: {
    SectionWrapper,
    PlannerComponent,
  },
};
</script>

<style></style>
