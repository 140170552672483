import { getDefaultState } from ".";
import axios from "axios";
import router from "../router";

export default {
  state: {
    isAuthenticated: false,
    me: {},
  },

  getters: {},

  mutations: {
    SET_ME(state, data) {
      state.isAuthenticated = true;
      state.me = {
        ...data,
      };
    },
    LOGOUT_USER(state) {
      Object.assign(state, getDefaultState());
      localStorage.removeItem("auth-token");
      localStorage.removeItem("refresh-token");
    },
  },

  actions: {
    async login({ dispatch }, authDetails) {
      // eslint-disable-next-line max-len
      const { data } = await axios.post("token/", authDetails);
      localStorage.setItem("auth-token", data.access);
      localStorage.setItem("refresh-token", data.refresh);
      router.push("/dashboard");

      dispatch("tryRefreshAll");
    },

    async getMe({ commit }) {
      const { data } = await axios.get("me/");
      commit("SET_ME", data);
    },

    async logOut({ commit }) {
      commit("LOGOUT_USER");
      router.push("/login");
    },
  },
};
