<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <ul role="list" class="divide-y divide-gray-800 border-t border-gray-800">
    <li v-for="project in currentProjects" :key="project.id">
      <div class="px-4 py-4 flex items-center sm:px-6">
        <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
          <div class="max-w-sm lg:max-w-xl">
            <div class="text-sm">
              <p class="font-medium text-indigo-600">
                {{ project.title }}
              </p>
              <p class="aaaflex-shrink-0 font-normal text-gray-500">
                {{ project.description }}
              </p>
            </div>
            <div class="mt-2 flex">
              <div class="flex items-center text-sm text-gray-500">
                <CalendarIcon
                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <p>
                  {{ " " }}
                  <time :datetime="project.start_date">{{
                    new Date(project.start_date).toLocaleDateString("de-DE")
                  }}</time>
                  -

                  <time :datetime="project.end_date">{{
                    new Date(project.end_date).toLocaleDateString("de-DE")
                  }}</time>
                </p>
              </div>
            </div>
          </div>

          <div class="truncate">
            <div class="flex text-sm text-right flex-col">
              <p class="font-medium text-indigo-600 truncate">
                {{ project.name }}
              </p>
              <p class="ml-1 flex-shrink-0 font-normal text-gray-500">
                {{ project.street }}, {{ project.zip }} {{ project.city }}
              </p>
            </div>
          </div>
        </div>
        <div class="ml-5 flex-shrink-0"></div>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import { CalendarIcon } from "@heroicons/vue/solid";
export default {
  computed: {
    ...mapGetters(["currentProjects"]),
  },
  components: {
    CalendarIcon,
  },
};
</script>

<style></style>
