import axios from "axios";

export default {
  state: {
    users: [],
    departments: [],
    permissionGroups: [],
  },

  getters: {
    getUserById: (state) => (userId) =>
      state.users.find((user) => user.id == userId),
    getDepartmentById: (state) => (departmentId) =>
      state.departments.find((department) => department.id == departmentId),
    getPermissionGroupById: (state) => (permissionGroupId) =>
      state.permissionGroups.find(
        (permissionGroup) => permissionGroup.id == permissionGroupId
      ),
  },

  mutations: {
    SET_USERS(state, users) {
      state.users = users;
    },

    SET_DEPARTMENTS(state, departments) {
      state.departments = departments;
    },
    SET_PERMISSION_GROUPS(state, permissionGroups) {
      state.permissionGroups = permissionGroups;
    },

    ADD_USER(state, user) {
      state.users = [...state.users, user];
    },

    ADD_DEPARTMENT(state, department) {
      state.departments = [...state.departments, department];
    },

    ADD_PERMISSION_GROUP(state, permissionGroup) {
      state.permissionGroups = [...state.permissionGroups, permissionGroup];
    },

    UPDATE_USER(state, user) {
      state.users = [
        ...state.users.filter((elem) => elem.id !== user.id),
        user,
      ];
    },

    UPDATE_DEPARTMENT(state, department) {
      state.departments = [
        ...state.departments.filter((elem) => elem.id !== department.id),
        department,
      ];
    },

    UPDATE_PERMISSION_GROUP(state, permissionGroup) {
      state.permissionGroups = [
        ...state.permissionGroups.filter(
          (elem) => elem.id !== permissionGroup.id
        ),
        permissionGroup,
      ];
    },

    DELETE_USER(state, userId) {
      state.users = [...state.users.filter((elem) => elem.id !== userId)];
    },

    DELETE_DEPARTMENT(state, departmentId) {
      state.departments = [
        ...state.departments.filter((elem) => elem.id !== departmentId),
      ];
    },

    DELETE_PERMISSION_GROUP(state, permissionGroupId) {
      state.permissionGroups = [
        ...state.permissionGroups.filter(
          (elem) => elem.id !== permissionGroupId
        ),
      ];
    },
  },

  actions: {
    async getUsers({ commit }) {
      const { data } = await axios.get("users/");
      commit("SET_USERS", data);
    },
    async getUser({ commit }, userId) {
      const { data } = await axios.get("users/" + userId);
      commit("UPDATE_USER", data);
      return data;
    },

    async getDepartments({ commit }) {
      const { data } = await axios.get("departments/");
      commit("SET_DEPARTMENTS", data);
    },

    async getPermissionGroups({ commit }) {
      const { data } = await axios.get("permission_groups/");
      commit("SET_PERMISSION_GROUPS", data);
    },

    async createUser({ commit }, userData) {
      const { data } = await axios.post("users/", userData);
      commit("ADD_USER", data);
    },

    async createDepartment({ commit }, departmentData) {
      const { data } = await axios.post("departments/", departmentData);
      commit("ADD_DEPARTMENT", data);
    },

    async createPermissionGroup({ commit }, permissionGroupData) {
      const { data } = await axios.post(
        "permission_groups/",
        permissionGroupData
      );
      commit("ADD_PERMISSION_GROUP", data);
    },

    async updateUser({ commit }, userData) {
      const { data } = await axios.patch(
        "users/" + userData.id.toString() + "/",
        userData
      );
      commit("UPDATE_USER", data);
    },

    async updateDepartment({ commit, dispatch }, departmentData) {
      const { data } = await axios.patch(
        "departments/" + departmentData.id.toString() + "/",
        departmentData
      );
      commit("UPDATE_DEPARTMENT", data);
      dispatch("getDepartments");
    },

    async updatePermissionGroup({ commit }, permissionGroupData) {
      const { data } = await axios.patch(
        "permission_groups/" + permissionGroupData.id.toString() + "/",
        permissionGroupData
      );
      commit("UPDATE_PERMISSION_GROUP", data);
    },

    async deleteUser({ commit }, id) {
      await axios.delete("users/" + id.toString() + "/");
      commit("DELETE_USER", id);
    },

    async deleteDepartment({ commit }, id) {
      await axios.delete("departments/" + id.toString() + "/");
      commit("DELETE_DEPARTMENT", id);
    },

    async deletePermissionGroup({ commit }, id) {
      await axios.delete("permission_groups/" + id.toString() + "/");
      commit("DELETE_PERMISSION_GROUP", id);
    },
  },
};
